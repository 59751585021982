import axios, { AxiosInstance } from 'axios';

import { Article, ArticleComment, Vendors } from './types';
import { Context } from './ContextProvider';
import { NotAurhorizedError } from './utils';

export class ApiClient {
	private readonly client: AxiosInstance;

	constructor(context: Context) {
		const token = context.getToken();
		this.client = axios.create({
			baseURL: process.env.REACT_APP_SERVER_URL ?? 'http://localhost:8000',
			headers: {
				Authorization: token ? `Bearer ${token}` : undefined,
			},
			timeout: 10000,
		});

		this.client.interceptors.response.use(
			(response) => {
				return response;
			},
			(error) => {
				if (error.response && [401, 403].includes(error.response.status)) {
					context.signout();
					context.closeModal();

					throw new NotAurhorizedError();
				}

				throw error;
			}
		);
	}

	async listArticles(
		term?: string,
		vendors?: Vendors[],
		page?: number
	): Promise<{ articles: Article[]; nextPage: number | null }> {
		const res = await this.client.get<{
			articles: Article[];
			nextPage: number | null;
		}>('/articles', {
			params: {
				search: term,
				vendors: vendors?.join(','),
				page,
				limit: 30,
			},
		});

		return {
			...res.data,
			articles: res.data.articles.sort(
				(a, b) => {
					const dateA = new Date(a.createdAt).getTime();
					const dateB = new Date(b.createdAt).getTime(); 
					return dateB - dateA;
				}
			),
		};
	}

	async thumbsUpArticleById(articleId: string) {
		await this.client.post(`/rate/${articleId}`, { value: 1 });
	}

	async thumbsDownArticleById(articleId: string) {
		await this.client.post(`/rate/${articleId}`, { value: -1 });
	}

	async ping(articleId: string, email: string) {
		await this.client.post(`/ping`, { articleId, email });
	}

	async listComments(articleId: string): Promise<ArticleComment[]> {
		const res = await this.client.get<ArticleComment[]>(
			`/article/${articleId}/comment/`
		);

		return res.data ?? [];
	}

	async postComment(articleId: string, message: string) {
		if (!message) return;

		await this.client.post(`/article/${articleId}/comment`, { message });
	}
}
